import PropTypes from 'prop-types';
import LinkWrapper from 'UI/components/LinkWrapper';
import Illustration from 'UI/components/Illustration';
import { formatDate } from 'utils/helper';
import { truncate } from 'lodash';
import Svg from 'UI/components/Svg';
import useCardProps from './utils/useCardProps';
import styles from './styles.module.scss';

const BlogCard = (props) => {
  const {
    articlePath,
    imageUrl,
    imageSizes,
    publishedAt,
    title,
    hashLinks,
    introduction,
  } = useCardProps(props);

  return (
    <LinkWrapper
      isLocalLink
      path={articlePath}
    >
      <article className={styles.article}>
        <Illustration
          {...imageSizes}
          src={imageUrl}
          className={styles.image}
          objectFit="cover"
          layout="fill"
          transparent
        />
        <div className={styles.articleContent}>
          <span className={styles.date}>
            {formatDate(publishedAt)}
          </span>
          <h3 className={styles.title}>
            {truncate(title, { length: 65 })}
            <Svg
              type="arrowNarrowUp"
              className={styles.arrowIcon}
            />
          </h3>
          <p className={styles.introduction}>
            {truncate(introduction, { length: 70 })}
          </p>
          <span className={styles.category}>
            {hashLinks}
          </span>
        </div>
      </article>
    </LinkWrapper>
  );
};

BlogCard.propTypes = {
  index: PropTypes.number,
  categoryTag: PropTypes.node,
  previewImageUrl: PropTypes.instanceOf(Object),
  publishedAt: PropTypes.string,
};

export default BlogCard;
